import React from 'react';
import placeholderImage from './buscaadorLogo.png'; // Ensure this path is correct

// Add an isLoading prop to manage the loading state


const CardList = ({ title, cards = [], currencySymbol, exchangeRate, emptyMessage, searchUrl, usePlaceholder, isLoading }) => {
  const loadingMessages = [
    "Fetcheando los cartones...",
    "Casteando la lista de precios...",
    "Invocando a los cartones...",
    "Cargando la lista de precios...",
    "Cascadeando a ver si sale algun precio piola..."
  ];

  return (
    <div className="card-list">
      <h2 className="card-list-title">{title}</h2>
      {searchUrl && (
        <a href={searchUrl} target="_blank" rel="noopener noreferrer" className="search-link">
          Ver la búsqueda en este sitio
        </a>
      )}

      {isLoading ? (
        <div>
          <img src={placeholderImage} alt="Loading..." className="loader-image" />
          <p>{loadingMessages[Math.floor(Math.random() * 5)]}</p>
        </div>
      ) : (
        <>
          {cards === "no search" ? (
            <div className="empty">{"No buscamo' acá"}</div>
          ) : cards !== "no search" && cards.length > 0 ? (
            <div className="cards horizontal-scroll">
              {cards.map((card, index) => (
                <div key={index} className="card-item">
                  <a href={card.link} target="_blank" rel="noopener noreferrer">
                    <img src={card.imageUrl || placeholderImage} alt={card.title} className="card-image" />
                    <p>{card.title}</p>
                    <p>{currencySymbol}{card.price.split('$')[1]}</p>
                  </a>
                </div>
              ))}
            </div>
          ) : (
            <div className="empty">{emptyMessage}</div>
          )}
        </>
      )}
    </div>
  );
};

export default CardList;