const cheerio = require('cheerio');

const scrapeMTGPirulo = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];

    $('.product').each((index, element) => {
        const title = $(element).find('.name').text().trim();
        const price = $(element).find('.price').text().trim();
        const stockStatus = $(element).find('.variant-qty').text().trim();
        const imageUrl = $(element).find('.image img').attr('src');

        if (stockStatus.toLowerCase().includes('out of stock')) {
            return; // Skip this item because it's out of stock
        }

        scrapedData.push({
            title,
            price,
            imageUrl,
            link: url
        });
    });

    return scrapedData;
};

const scrapeMagicDealers = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];

    $('.product').each((index, element) => {
        const title = $(element).find('.name').text().trim();
        const price = $(element).find('.price').text().trim();
        const stockStatus = $(element).find('.variant-qty').text().trim();
        const imageUrl = $(element).find('.image img').attr('src');

        if (stockStatus.toLowerCase().includes('out of stock')) {
            return; // Skip this item because it's out of stock
        }

        scrapedData.push({
            title,
            price,
            imageUrl,
            link: url
        });
    });

    return scrapedData;
};

const scrapeMagicLair = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];

    $('.collectionGrid .productCard__card').each((index, element) => {
        const title = $(element).find('.productCard__title a').text().trim();
        const price = $(element).find('.productCard__price').text().trim();
        const imageUrl = $(element).find('.productCard__img').attr('data-src');
        const link = 'https://www.magiclair.com.ar' + $(element).find('.productCard__title a').attr('href');

        // Check if the product is out of stock
        const isOutOfStock = $(element).find('.productCard__button--outOfStock').length > 0;

        // Only include in-stock items
        if (!isOutOfStock) {
            scrapedData.push({
                title,
                price,
                imageUrl,
                link,
            });
        }
    });
    return scrapedData;
};


const scrapeLaBatikuevaStore = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];

    $('.js-product-container').each((index, element) => {
        // Check stock status
        const isOutOfStock = $(element).find('.label-default').text().trim() === 'Sin stock';
        if (isOutOfStock) {
            return; // Skip this item because it's out of stock
        }

        const title = $(element).find('.js-item-name').text().trim();

        // Extract and handle image URL
        const imageUrlRelative = $(element).find('.js-item-image').attr('data-srcset').split(' ')[0];
        const imageUrl = `https:${imageUrlRelative}`; // Assuming it needs 'https:' prefix

        // Extract price (modify selector if necessary)
        const price = $(element).find('.item-price').text().trim() || '$No muestra precio'; // Replace with actual logic to extract price

        const link = $(element).find('.item-link').attr('href');

        scrapedData.push({
            title,
            price,
            imageUrl,
            link,
        });
    });

    return scrapedData;
};


const parseTheMagicTutor = (html, searchTerm, url) => {
    const $ = cheerio.load(html);
    const textContent = $('xmp').text();
    const lines = textContent.split('\n');

    return lines.map(line => {
        const parts = line.split('\t').map(part => part.trim());
        if (parts.length < 2) return null;

        const name = parts[0];
        const price = parts[1];

        // Check if the line includes the search term and has a valid price
        if (name.toLowerCase().includes(searchTerm.toLowerCase()) && price !== '$ -') {
            return {
                title: name,
                price: price,
                imageUrl: '', // Placeholder or empty
                link: url // General link or empty
            };
        }
        return null;
    }).filter(card => card); // Filter out null entries
};

const scrapeMercadiaCityStore = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];
    $('.product-item').each((index, element) => {
        // Check stock status
        // const isOutOfStock = $(element).find('.label-default').text().trim() === 'Sin stock';
        // if (isOutOfStock) {
        //     return; // Skip this item because it's out of stock
        // }
        const title = $(element).find('.product-item-name').text().trim();
        // Extract and handle image URL
        const imageUrlElement = $(element).find('.main-img');
        console.log('Image URL element:', imageUrlElement);
        
        const imageUrlRelative = imageUrlElement.attr('src');
        console.log('Relative image URL:', imageUrlRelative);
        
        const imageUrl = imageUrlRelative ? (imageUrlRelative.startsWith('http') ? imageUrlRelative : `https:${imageUrlRelative}`) : 'No image';
        console.log('Full image URL:', imageUrl);
        
        // Extract price (modify selector if necessary)
        const price = $(element).find('.price').text().trim() || '$No muestra precio'; // Replace with actual logic to extract price

        const link = $(element).find('.product-item-link').attr('href');

        scrapedData.push({
            title,
            price,
            imageUrl,
            link,
        });
    });

    return scrapedData;
};

const scrapeMadToyz = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];
    $('.products .product').each((index, element) => {
        // Extract title
        const title = $(element).find('.woocommerce-loop-product__title').text().trim();

        // Extract and handle image URL
        const imageUrlElement = $(element).find('img.swiper-lazy');
        const imageUrlRelative = imageUrlElement.attr('src');
        const imageUrl = imageUrlRelative ? (imageUrlRelative.startsWith('http') ? imageUrlRelative : `https:${imageUrlRelative}`) : 'No image';

        // Extract price
        let price = $(element).find('.onsale').text().trim().split(':')[1]?.replace(/[a-zA-Z]/g, '');
        
        if (!price) {
            price = $(element).find('bdi').text().trim()?.replace(/[a-zA-Z]/g, '');
        }
        if (!price) {
            price = '$No muestra precio';
        }
        price = `\$${price.replace('$','')}`
        // Log price for debugging
        console.log(`Price for product "${title}":`, price);

        // Extract link
        const link = $(element).find('.woocommerce-LoopProduct-link').attr('href');

        scrapedData.push({
            title,
            price,
            imageUrl,
            link,
        });
    });

    return scrapedData;
};





module.exports = {
    scrapeMTGPirulo,
    scrapeMagicDealers,
    scrapeMagicLair,
    scrapeLaBatikuevaStore,
    parseTheMagicTutor,
    scrapeMercadiaCityStore,
    scrapeMadToyz
};
